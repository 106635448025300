import React from 'react';

import styled from 'styled-components';

const IFrameContainer = styled.div`
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
`;

const IFrame = styled.iframe`
    border: 0;
    height: 73%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

/**
 * Loads the National DDF Report from PowerBI.
 *
 * @returns - The PowerBI National DDF Report iFrame.
 */
const NationalDDFReport = (): JSX.Element => (
    <IFrameContainer>
        <IFrame
            title="NationalDDFReport_Combined"
            src={process.env.REACT_APP_NATIONAL_DDF_URL}
            frameBorder={0}
            allowFullScreen
        />
    </IFrameContainer>
);

export default NationalDDFReport;
