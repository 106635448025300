/**
 * Wait times report component.
 *
 * @author Anthony P. Pancerella
 * @author Brandan D. Reed
 */
import React from 'react';
import { connect } from 'react-redux';

import _forOwn from 'lodash/forOwn';
import _groupBy from 'lodash/groupBy';
import styled from 'styled-components';

import Button from 'Lib/Button';
import DDFContainer from 'Lib/DDFSlides/DDFContainer';
import { getWaitTimesSelector } from 'Modules/UnderstandDemand/Services/selector';
import { getCurrentMarketName } from 'Modules/UserSession/selector';

const IFrameContainer = styled.div`
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
`;

const IFrame = styled.iframe`
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

class UDWaitTimes extends React.Component {
    componentWillMount() {
        const { marketName } = this.props;
        if (marketName && this.props.waitTimesData.list.length === 0) {
            this.props.fetchWaitTimesAsync({ market: marketName, reportingType: '', facility: '' });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.marketName !== this.props.marketName) {
            this.refs.Facility.cleanFiltered();
            this.props.fetchWaitTimesAsync({
                market: nextProps.marketName,
                reportingType: '',
                facility: ''
            });
        }
    }

    _setTableOption(data) {
        if (data.loading) {
            return (
                <div className="progress">
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: '100%', backgroundRepeat: 'repeat' }}
                    >
                        Fetching Data
                    </div>
                </div>
            );
        }
        return 'No data available in the table';
    }

    facilityFilter() {
        const { list } = this.props.waitTimesData;
        const filterArray = [];
        _forOwn(_groupBy(list, 'Facility'), (value, key) => {
            filterArray.push(key);
        });
        const result = {};
        for (let i = 0; i < filterArray.length; i++) {
            result[filterArray[i]] = filterArray[i];
        }
        return result;
    }

    render() {
        return (
            <div style={{ paddingTop: 15 }}>
                <DDFContainer
                    text={'There is relevant data published in the Data Discovery and Findings.'}
                    reports={[
                        {
                            title: 'Average Wait Time for Top 10 Consults',
                            slideNumbers: [114]
                        },
                        {
                            title: 'Longest Wait Time – Top 10 Consults',
                            slideNumbers: [115]
                        }
                    ]}
                ></DDFContainer>
                <IFrameContainer>
                    <IFrame
                        title="WaitTimes"
                        src={process.env.REACT_APP_WAIT_TIMES_URL}
                        frameborder="0"
                        allowFullScreen="true"
                    ></IFrame>
                </IFrameContainer>
            </div>
        );
    }

    deleteWaitTimeBtn = (cell, row, enumObject, rowIndex) => (
        <Button
            color="danger"
            type="button"
            label="Delete"
            onClick={() =>
                this.deleteWaitTime({
                    id: row.Id,
                    market: this.props.marketName,
                    facility: row.Facility
                })
            }
            btnPaddingTop={1}
            btnPaddingBottom={1}
        />
    );

    deleteWaitTime = (id, market, facility) => {
        this.props.deleteWaitTimeReport(id, market, facility);
    };
}
const mapDispatchToProps = (dispatch) => ({
    fetchWaitTimesAsync: (payload) => dispatch.WaitTimesModel.fetchWaitTimesAsync(payload),
    deleteWaitTimesAsync: (payload) => dispatch.WaitTimesModel.deleteWaitTimesAsync(payload)
});
const mapStateToProps = (state) => ({
    marketName: getCurrentMarketName(state),
    waitTimesData: getWaitTimesSelector(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(UDWaitTimes);
