const STORAGE_PATH = './static/storage';

export const AMBULATORY_SPACE_ESTIMATOR_TOOL_PATH = `${STORAGE_PATH}/VHA Ambulatory Services Space Estimator Tool BY2022.xlsx`;
export const INPATIENT_SPACE_ESTIMATOR_TOOL_PATH = `${STORAGE_PATH}/VHA Inpatient Bed Space Estimator Tool BY2022.xlsx`;
export const COST_DATA_DESCRIPTION_PATH = `${STORAGE_PATH}/HSPA Cost Data Description.pdf`;
export const LONG_RANGE_PLAN = `%{STORAGE_PATH}/FY 2022-2025 VHA Long-Range Plan.pdf`;

export const USER_GUIDE_PATHS = {
    ENVIRONMENTAL_SCAN: `${STORAGE_PATH}/HSPA Environmental Scan.docx`,
    STRATEGIC_OUTLOOK: `${STORAGE_PATH}/HSPA Strategic Outlook.docx`,
    UNDERSTAND_DEMAND: `${STORAGE_PATH}/HSPA Understand Demand.docx`,
    UNDERSTAND_CAPACITY: `${STORAGE_PATH}/HSPA Understand Capacity.docx`,
    MATCH: `${STORAGE_PATH}/HSPA Match.docx`,
    REPORT: `${STORAGE_PATH}/HSPA Report.docx`
};
